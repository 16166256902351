import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { SMenu, SMenuItem } from "./styles"
import { useState } from "react"


const menus = [
    {
        name: "Home",
        icon: () => <i className="fa-solid fa-house"></i>,
        url: "/dashboard/home",
        subs: []
    },
    // {
    //     name: "Clientes",
    //     icon: () => <i className="fa-solid fa-users"></i>,
    //     url: "/dashboard/clients",
    //     subs: []
    // },
    {
        name: "IA",
        icon: () => <i className="fa-solid fa-robot"></i>,
        url: "/dashboard/ia",
        subs: []
    },
    {
        name: "Clientes",
        icon: () => <i class="fa-solid fa-users"></i>,
        url: "/dashboard/clientes",
        subs: []
    },
    {
        name: "Configurações",
        icon: () => <i className="fa-solid fa-gear"></i>,
        url: "/dashboard/config",
        subs: []
    }
]

const MenuItem = ({_index, _location, _menu, navigate, open, setOpen}) => {

    const is_current = () => {
        if(_menu.subs.length && open === _index) return true
        return _location.pathname.includes(_menu.url)
    }

    return (
        <SMenuItem current={is_current()} onClick={() => {
            if(!_menu.subs.length) {
                navigate(_menu.url)
                setOpen(-1)
            } else {
                if(open === _index) {
                    setOpen(-1)
                } else {
                    setOpen(_index)
                }
            }
        }}>
            <div className="primary-item">
                <_menu.icon/>
                {
                    _menu.subs.length ?
                        <span>{_menu.name}</span>
                    : <NavLink to={_menu.url}>{_menu.name}</NavLink>
                }
            </div>
            {
                _menu.subs.length ?
                    <div className="subs">
                        {
                            _menu.subs.map( (sub, x) => (
                                <div key={"sm-" + x} className={"sub-menu" + (_location.pathname.includes(sub.url) ? " sub-active" : "")} onClick={() => navigate(sub.url)}>
                                    <i className="fa-solid fa-circle"></i>
                                    <span>{sub.name}</span>
                                </div>
                            ))
                        }
                    </div>
                : null
            }
      
        </SMenuItem>
    )
}   


const Menu = () => {
    const [open, setOpen] = useState(-1);
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <SMenu>
            {
                menus.map( (_menu, k) => (
                    <MenuItem open={open} setOpen={setOpen} key={"m-" + k} _index={k} _location={location} _menu={_menu} navigate={navigate}/>
                ))
            }
        </SMenu>
    )
}


export default Menu