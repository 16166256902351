import { useState } from "react";
import Checkbox from "../../../../../components/Checkbox";
import Input from "../../../../../components/Input";
import Label from "../../../../../components/Label";
import { SClientModalEdit } from "./styles";
import Button from "../../../../../components/Button";
import api from "../../../../../services/api";

const ClientModalEdit = ({close, client}) => {
    const [form, setForm] = useState({
        _id:                        client._id,
        name:                       client.name,
        wpp:                        client.wpp,
        free_reading_questions:     client.free_reading_questions,
        status_free_reading:        client.status_free_reading,
        single_reading_questions:   client.single_reading_questions,
        status_single_reading:      client.status_single_reading,
        trilha_reading_questions:   client.trilha_reading_questions,
        dailyHoroscopeSentAt:       client.dailyHoroscopeSentAt,
        monthlyReadingSentAt:       client.monthlyReadingSentAt,
        lastPaidAt:                 client.lastPaidAt,
        single_reading_startAt:     client.single_reading_startAt,
        single_reading_expireAt:    client.single_reading_expireAt
    });

    const setValue = (name, value) => {
        let extra = {
            [name]: value
        };

        if(name === "status_free_reading" && value) {
            extra.status_single_reading = false;
            extra.status_trilha_reading = false;
        }

        if(name === "status_single_reading" && value) {
            extra.status_free_reading   = false;
            extra.status_trilha_reading = false;
        }

        if(name === "status_trilha_reading" && value) {
            extra.status_single_reading = false;
            extra.status_free_reading   = false;
        }

        setForm({...form, ...extra})
    }

    const save = async () => {
        const res = await api({
            method: 'post',
            action: "saveClient",
            data: form,
            auth: true
        });
        
        close()
    }

    return (
        <SClientModalEdit>
            <div className="client-modal">
                <div className="client-close">
                    <i class="fa-solid fa-xmark" onClick={() => close()}/>
                </div>
                <h5 className="text-center mb-5">EDITAR CLIENTE</h5>
                <div className="row mb-5">
                    <div className="col-8">
                        <Label>NOME COMPLETO:</Label>
                        <Input value={form.name} type="text" name="name" setValue={setValue} />
                    </div>
                    <div className="col-4">
                        <Label>WHATSAPP:</Label>
                        <Input value={form.wpp} disabled={true} type="text" name="wpp" setValue={setValue} />
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-12 mb-5">
                        <div className="row">
                            <div className="col-12">
                                <Checkbox value={form.status_free_reading} setValue={setValue} name="status_free_reading" label={"Consulta experimental ativa"}/>
                            </div>    
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Label>CONSULTAS EXPERIMENTAIS:</Label>
                                <Input value={form.free_reading_questions} type="number" name="free_reading_questions" setValue={setValue} />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <Checkbox value={form.status_single_reading} setValue={setValue} name="status_single_reading" label={"Consulta única ativa"}/>
                            </div>
                        </div>
                   
                        <div className="row">
                            <div className="col-4">
                                <Label>COSULTAS ÚNICAS:</Label>
                                <Input value={form.single_reading_questions} type="number" name="single_reading_questions" setValue={setValue}/>
                            </div>
                            <div className="col-4">
                                <Label>INÍCIO:</Label>
                                <Input value={form.single_reading_startAt} type="date" name="single_reading_startAt" setValue={setValue}/>
                            </div>
                            <div className="col-4">
                                <Label>EXPIRA EM:</Label>
                                <Input value={form.single_reading_expireAt} type="date" name="single_reading_expireAt" setValue={setValue}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Checkbox value={form.status_trilha_reading} setValue={setValue} name="status_trilha_reading" label={"Trilha transformadora ativa"}/>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 row">
                                <div className="col-4">
                                    <Label>último pagamento</Label>
                                    <Input value={form.lastPaidAt} type="date" name="lastPaidAt" setValue={setValue} />
                                </div>
                                <div className="col-4">
                                    <Label>horóscopo diário</Label>
                                    <Input value={form.dailyHoroscopeSentAt} type="date" name="dailyHoroscopeSentAt" setValue={setValue} />
                                </div>
                                <div className="col-4">
                                    <Label>previsão mensal:</Label>
                                    <Input value={form.monthlyReadingSentAt} type="date" name="monthlyReadingSentAt" setValue={setValue} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Button color="primary" onClick={() => save()}>
                        SALVAR
                    </Button>
                </div>
            </div>
        </SClientModalEdit>
    )
}


export default ClientModalEdit