import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    Routes,
    RouterProvider,
  } from "react-router-dom";
  
import pages from "./pages";
import DashboardLayout from "./layouts/Dashboard";


const Rotas = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<pages.PageLogin />}/>
            <Route path="/dashboard"    element={<DashboardLayout />}>
                <Route path="home"      element={<pages.Dashboard.Home />} />
                <Route path="ia"        element={<pages.Dashboard.IA />} />
                <Route path="config"    element={<pages.Dashboard.Config />} />
                <Route path="clientes"  element={<pages.Dashboard.Client />} />
            </Route>
        </>
    )
);



export default Rotas;