import styled from "@emotion/styled";




export const SClientModalEdit = styled.div`
    position: fixed;
    background-color: rgba(0,0,0, 0.4);
    width:  100%;
    height: 100%;
    left: 0;
    top:  0;
    display: flex;
    justify-content: center;
    align-items: center;
    .client-modal {
        background-color: #fff;
        border-radius: 6px;
        padding: 24px;
        width: 40%;
        position: relative;
        .client-close {
            position: absolute;
            right: 24px;
            top: 24px;
            i {
                font-size: 24px;
                cursor: pointer;
            }
        }
    
    }

`;