import styled from "@emotion/styled";
import colors from "../../theme/colors";


export const SAccordion = styled.div`
    border: 1px solid ${colors.primary.medium};
    border-radius: 12px;
    margin-bottom: 24px;
    .accordion-title {
        padding: 12px;
        cursor: pointer;
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        span {
            font-size: 18px;
            margin: 0;
            margin-right: 24px;
        }
        i {
            font-size: 24px;
        }
    }
    .accordion-body {
        padding: 24px;
        textarea {
            border: 1px solid ${colors.primary.medium};
            padding: 24px;
        }
    }
`;