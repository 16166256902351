import { useState } from "react"
import { SAccordion } from "./styles"

const Accordion = ({children, title}) => {
    const [open, close] = useState(false)
    return (
        <SAccordion>
            <div className="accordion-title" onClick={() => close(!open)}>
                <div><span>{title}</span><i class={"fa-solid fa-caret-" + (!open ? "right" : "down")}></i></div>
            </div>
            {
                open ?
                    <div className="accordion-body">
                        {children}
                    </div>
                : null
            }
        </SAccordion>
    )
}

export default Accordion