import styled from "@emotion/styled";
import colors from "../../../theme/colors";


export const SPageClientes = styled.div`
    .client-table {
        width: 100%;
        thead {
            th {
                font-weight: 400;
                padding: 4px 12px;
            }
            background-color: ${colors.dark.light};
        }
        tbody {
            td {
                padding: 4px 12px;
                font-size: 14px;
            }
            .action {
                i {
                    font-size: 18px;
                    margin-left: 24px;
                    cursor: pointer;
                }
                .fa-pen {
                    color: ${colors.secondary.medium};
                }
                .fa-trash {
                    color: ${colors.primary.medium};
                }
            }
        }
    }
`;