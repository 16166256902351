

const API_PATHS = {
    "signin":           "/auth/signin",
    "valid":            "/auth/valid",
    
    "updateConfig":     "/config/update",
    "getConfig":        "/config/get",
    "whatsappNew":      "/whatsapp/new",
    "whatsappGet":      "/whatsapp/get",
    "iaSendMessage":    "/ia/message",
    "iaGetMessages":    "/ia/messages",
    "iaPutMessages":    "/ia/messages",
    "iaPutPrompt":      "/ia/prompt",
    "iaGetSimulator":   "/ia/simulator",
    "iaPutSimulator":   "/ia/simulator",
    "getClients":       "/client/list",
    "saveClient":       "/client/save",
}


export default API_PATHS