import PageDashboard from "./Dashboard/Home"
import PageDashboardConfig from "./Dashboard/Config"
import PageLogin from "./Login"
import IAPage from "./Dashboard/IA"
import PageClientes from "./Dashboard/Clientes"


const pages = {
    PageLogin,
    Dashboard: {
        Home:   PageDashboard,
        IA:     IAPage,
        Config: PageDashboardConfig,
        Client: PageClientes
    }
}


export default pages