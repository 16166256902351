import { useState } from "react";
import Container from "../../../components/Conteiner";
import api from "../../../services/api";
import { SPageClientes } from "./styles";
import { useEffect } from "react";
import ClientModalEdit from "./components/ClientModalEdit";


const PageClientes = ({client}) => {
    const [clients, setClients] = useState({
        queried: false,
        data: []
    });

    const [open, setOpen] = useState({open: false, client: {}})

    const getClients = async () => {
        const res = await api({
            method: 'get',
            action: "getClients",
            auth: true
        });
        if(res.data) {
            setClients({ queried: true, data: res.data});
        }
    }

    useEffect(() => {
        if(!clients.queried) {
            getClients()
        }
    },[clients])

    return  (
        <SPageClientes>
            {
                open.open ? <ClientModalEdit client={open.data} close={() => {setOpen({open: false, data: {}}); getClients()}}/> : null
            }
            <h6 className="mb-2">CLIENTES</h6>
            <Container>
                <table className="client-table">
                    <thead>
                        <tr>
                            <th>NOME</th>
                            <th>WHATSAPP</th>
                            <th>TIPO</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            clients.data.map( (client, k) => (
                                <tr>
                                    <td>{client.name}</td>
                                    <td>{client.wpp}</td>
                                    <td>Experimental</td>
                                    <td><div className="action"><i class="fa-solid fa-pen" onClick={() => setOpen({open: true, data: client})}/> <i class="fa-solid fa-trash"/></div></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </Container>
        </SPageClientes>
    )
}


export default PageClientes;