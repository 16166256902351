import { useState } from "react"
import TextArea from "../../../components/TextArea"
import { StyledAI } from "./styles"
import IAChat from "../../../components/IAChat";
import Container from "../../../components/Conteiner";
import Button from "../../../components/Button";
import api from "../../../services/api";
import { useEffect } from "react";
import Accordion from "../../../components/Accordion";
import Label from "../../../components/Label";
import Input from "../../../components/Input";
import Checkbox from "../../../components/Checkbox";


global.messages = [];

const IAPage = () => {
    const [form, setForm] = useState({
        queried: false,
            free_reading_questions:     0,
            status_free_reading:        false,
            single_reading_questions:   0,
            status_single_reading:      false,
            trilha_reading_questions:   0
    });

    const [prompt, setPrompt] = useState({
        queried: false,
        prompt: "",
    });

    const [listening, setListening] = useState(0)

    const [chat, setChat] = useState({
        queried: false,
        input: ""
    })

    const [messages, setMessages] = useState({
        queried: false,
        data: []
    })

    const setInput = (name, value) => {
        setChat({
            ...chat,
            [name]: value
        })
    }

    const getPrompt = async () => {
        const res = await api({
            method: 'get',
            action: "getConfig",
            auth: true
        });
        if(res.data) {
            setPrompt({queried: true, prompt: res.data.prompt})
        }
    }

    const setMessagesReaded = async (ids) => {
        const res = await api({
            method: 'put',
            action: "iaPutMessages",
            auth: true,
            data: {
                messages_readed: JSON.stringify(ids)
            }
        });
    }

    const getMessages = async () => {
        const res = await api({
            method: 'get',
            action: "iaGetMessages",
            auth: true
        });
        if(res.data) {
            global.messages = [...global.messages, ...res.data]
            await setMessagesReaded(res.data.map( i => i._id))
            setMessages({queried: true, data: global.messages})
        }
        setListening((new Date).getTime())

        setTimeout(scrollToBottom, 500)
    }

    const updatePrompt  = async () => {
        const res = await api({
            method: 'post',
            action: "iaPutPrompt",
            data: {
                prompt: prompt.prompt,
            },
            auth: true
        });
        global.messages = [];
        setMessages({queried: true, data: []})
    }

    const updateSimulator = async () => {
        const res = await api({
            method: 'put',
            action: "iaPutSimulator",
            data: {
                free_reading_questions:     form.free_reading_questions,
                status_free_reading:        form.status_free_reading,
                single_reading_questions:   form.single_reading_questions,
                status_single_reading:      form.status_single_reading,
                trilha_reading_questions:   form.trilha_reading_questions
            },
            auth: true
        });
    }

    const getSimulator = async () => {
        const res = await api({
            method: 'get',
            action: "iaGetSimulator",
            auth: true
        });

        if(res.data) {
            setForm({
                queried: true,
                free_reading_questions:     res.data.free_reading_questions,
                status_free_reading:        res.data.status_free_reading,
                single_reading_questions:   res.data.single_reading_questions,
                status_single_reading:      res.data.status_single_reading,
                trilha_reading_questions:   res.data.trilha_reading_questions
            })
        }
    }

    const sendMessage = async () => {
        if(chat.input) {
            const text = chat.input;
            global.messages = [...global.messages, {
                text,
                role: "user"
            }]
            
            setChat({...chat,
                input: ""
            })


            const res = await api({
                method: 'post',
                action: "iaSendMessage",
                data: {
                    text
                },
                auth: true
            });
            
        }
    }

    const setValue = (name, value) => {
        let extra = {
            [name]: value
        };

        if(name === "status_free_reading" && value) {
            extra.status_single_reading = false;
            extra.status_trilha_reading = false;
        }

        if(name === "status_single_reading" && value) {
            extra.status_free_reading   = false;
            extra.status_trilha_reading = false;
        }

        if(name === "status_trilha_reading" && value) {
            extra.status_single_reading = false;
            extra.status_free_reading   = false;
        }

        setForm({...form, ...extra})
    }

    const handleForm = (name, value) => {
        setPrompt({...prompt, [name]: value})
    }

    const scrollToBottom = () => {
        const e = document.getElementsByClassName("chat-messages");
        if(e && e[0]) {
            e[0].scrollTop = e[0].scrollHeight;
        }
    }

    useEffect(() => {
        if(!prompt.queried)  {
            getPrompt()
        }
    },[prompt])

    useEffect(() => {
        if(!form.queried)  {
            getSimulator()
        }
    },[form])

    // useEffect(() => {
    //     if(!messages.queried) {
    //         getMessages()
    //     }

    //     const timer = setInterval(() => getMessages(), 2000)
      
    //     return () => clearInterval(timer)


    // },[listening])

    return (
        <StyledAI>
            <div className="mb-5">
                <h6 className="mb-2">SIMULAÇÃO</h6>
                <Container>
                    <Accordion title="Configurações">
                        <div className="row">
                            <div className="col-12">
                                <Checkbox value={form.status_free_reading} setValue={setValue} name="status_free_reading" label={"Consulta experimental ativa"}/>
                            </div>
                            <div className="col-12">
                                <Label>CONSULTAS EXPERIMENTAIS:</Label>
                                <Input value={form.free_reading_questions} type="number" name="free_reading_questions" setValue={setValue} />
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <Checkbox value={form.status_single_reading} setValue={setValue} name="status_single_reading" label={"Consulta única ativa"}/>
                            </div>
                            <div className="col-12">
                                <Label>COSULTAS ÚNICA:</Label>
                                <Input value={form.single_reading_questions} type="number" name="single_reading_questions" setValue={setValue}/>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <Checkbox value={form.status_trilha_reading} setValue={setValue} name="status_trilha_reading" label={"Trilha transformadora ativa"}/>
                            </div>
                            <div className="mt-5">
                                <Button onClick={ () => updateSimulator()}>
                                    Salvar
                                </Button>
                            </div>
                        </div>
                    </Accordion>
                </Container>
            </div>
            <div className="prompt-editor mb-5">
                <h6 className="mb-2">Comandos (Prompts)</h6>
                <Container>
                    <TextArea value={prompt.prompt} setValue={handleForm} name={"prompt"} placeholder="Comandos..."/>
                    <div className="mt-3">
                        <Button onClick={ () => updatePrompt()}>
                            Salvar
                        </Button>
                    </div>
                </Container>
            </div>
            <div className="ia-chat">
                <h6 className="mb-2">Chat</h6>
                <Container>
                    <IAChat messages={messages.data} name="input" value={chat.input} setValue={setInput} sendMessage={() => sendMessage()}/>
                </Container>
            </div>
        </StyledAI>
    )
}

export default IAPage